<template>
  <div class="cardtree">
    <img
      class="cardtree__body_back-image"
      src="@/assets/admin/icon/circle-back.png"
      alt="close"
      v-if="backLevel"
      @click="$emit('backLevel')"
    />
    <div
      class="cardtree__body"
      :class="level !== 'basic' ? `cardtree__body_${level}` : ''"
      @click="$emit('cardPressed')"
    >
      <p
        class="cardtree__body-level"
        :class="level !== 'basic' ? `cardtree__body-level_${level}` : ''"
      >
        {{ level?.toUpperCase() }}
      </p>
      <img
        class="cardtree__body-image"
        :src="require(`@/assets/admin/icon/${level}.svg`)"
        alt="person"
      />
      <p class="cardtree__body-name">{{ name || "-" }}</p>
      <div
        class="cardtree__body-box"
        :class="level !== 'basic' ? `cardtree__body-box_${level}` : ''"
      >
        <p class="cardtree__body-box-text">{{ id || "-" }}</p>
        <p class="cardtree__body-box-text">Generasi {{ generation || "-" }}</p>
        <p class="cardtree__body-box-text">PV {{ pv || "-" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: String,
      default: "basic",
    },
    name: {
      type: String,
      default: "-",
    },
    id: {
      type: String,
      default: "-",
    },
    generation: {
      type: String,
      default: "-",
    },
    backLevel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.cardtree {
  position: relative;
}

.cardtree__body {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 6px;
  background: #ffffff;
  width: 165px;
  /* height: 207px; */
  border: 3px solid;
  border-image: linear-gradient(180deg, #01be2a, #01be2a, #01be2a) 30;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.cardtree__body_back-image {
  cursor: pointer;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0px;
  left: -48px;
}

.cardtree__body_platinum {
  border: 3px solid;
  border-image: linear-gradient(
      180deg,
      #000000 6.57%,
      #434343 26.3%,
      #6d6d6d 50.98%,
      #474646 80.48%,
      #000000 107.61%
    )
    30;
}

.cardtree__body_gold {
  border: 3px solid;
  border-image: linear-gradient(
      180deg,
      #d6b450 6.85%,
      #dbd254 51.44%,
      #aa8431 101.34%
    )
    30;
}

.cardtree__body_silver {
  border: 3px solid;
  border-image: linear-gradient(
      180deg,
      #adabab 6.85%,
      #f0eeef 51.44%,
      #a0a09e 101.34%
    )
    30;
}

.cardtree__body_standard {
  border: 3px solid;
  border-image: linear-gradient(
      180deg,
      #be4c25 6.85%,
      #fdd19c 51.44%,
      #e2864c 101.34%
    )
    30;
}

.cardtree__body-level {
  font-size: 18px;
  line-height: 26px;
  color: #01be2a;
  font-weight: 900;
  margin-bottom: 8px;
}

.cardtree__body-level_platinum {
  background-image: linear-gradient(
    180deg,
    #000000 6.57%,
    #434343 26.3%,
    #6d6d6d 50.98%,
    #474646 80.48%,
    #000000 107.61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cardtree__body-level_gold {
  background-image: linear-gradient(
    180deg,
    #d6b450 6.85%,
    #dbd254 51.44%,
    #aa8431 101.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cardtree__body-level_silver {
  background-image: linear-gradient(
    180deg,
    #878787 6.85%,
    #d3d3d3 51.44%,
    #a0a09e 101.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cardtree__body-level_standard {
  background-image: linear-gradient(
    180deg,
    #be4c25 6.85%,
    #fdd19c 51.44%,
    #e2864c 101.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cardtree__body-image {
  margin-bottom: 12px;
}

.cardtree__body-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0px 6px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  font-weight: 900;
  text-align: center;
}

.cardtree__body-box {
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #01be2a;
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.cardtree__body-box_platinum {
  background-image: linear-gradient(
    180deg,
    #000000 6.57%,
    #434343 26.3%,
    #6d6d6d 50.98%,
    #474646 80.48%,
    #000000 107.61%
  );
}

.cardtree__body-box_gold {
  background-image: linear-gradient(
    180deg,
    #d6b450 6.85%,
    #dbd254 51.44%,
    #aa8431 101.34%
  );
}

.cardtree__body-box_silver {
  background-image: linear-gradient(
    180deg,
    #adabab 6.85%,
    #d8d8d8 51.44%,
    #a0a09e 101.34%
  );
}

.cardtree__body-box_standard {
  background-image: linear-gradient(
    180deg,
    #be4c25 5.49%,
    #fdd19c 50.16%,
    #e2864c 100.15%
  );
}

.cardtree__body-box-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  color: white;
}
</style>
