<template>
  <div class="usercontent">
    <div class="usercontent__list">
      <div class="usercontent__list-menu">
        <div class="usercontent__list-search">
          <input
            v-model="searchInput"
            type="text"
            class="usercontent__list-search-input"
            placeholder="Cari data..."
          />
          <button
            type="submit"
            class="usercontent__list-search-button"
            @click="searchData()"
          >
            <img
              src="@/assets/admin/icon/search.png"
              alt="search"
              class="usercontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="usercontent__list-dropdown">
          <div
            class="usercontent__list-dropdown-box"
            @click="toggleFilter()"
            v-click-outside="closeFilter"
          >
            <p class="usercontent__list-dropdown-box-text">
              {{ selectedFilter?.name }}
            </p>
            <div
              class="usercontent__list-dropdown-box-content"
              v-if="filterOpened"
            >
              <p
                class="usercontent__list-dropdown-box-content-text"
                :class="
                  index === filterList.length - 1
                    ? 'usercontent__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in filterList"
                :key="index"
                @click="setSelectedFilter(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="usercontent__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="usercontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="usercontent__list-dropdown">
          <div
            class="usercontent__list-dropdown-box"
            @click="toggleSort()"
            v-click-outside="closeSort"
          >
            <p class="usercontent__list-dropdown-box-text">
              {{ selectedSort?.name }}
            </p>
            <img
              src="@/assets/admin/icon/sort-asc-black.png"
              alt="search"
              class="usercontent__list-search-button-img"
              v-if="selectedSort?.sortAsc"
            />
            <img
              src="@/assets/admin/icon/sort-desc-black.png"
              alt="search"
              class="usercontent__list-search-button-img"
              v-else
            />
            <div
              class="usercontent__list-dropdown-box-content"
              v-if="sortOpened"
            >
              <p
                class="usercontent__list-dropdown-box-content-text"
                :class="
                  index === sortList.length - 1
                    ? 'usercontent__list-dropdown-box-content-text_no-bottom'
                    : ''
                "
                v-for="(dt, index) in sortList"
                :key="index"
                @click="setSelectedSort(dt)"
              >
                {{ dt.name }}
              </p>
            </div>
          </div>
          <button type="submit" class="usercontent__list-search-button">
            <img
              src="@/assets/admin/icon/filter.png"
              alt="search"
              class="usercontent__list-search-button-img"
            />
          </button>
        </div>
        <div class="usercontent__list-adddelete usercontent__grayscale">
          <!-- @buttonClicked="showAddModal(), setType('add')" -->
          <CustomButton :button-text="'Add'"></CustomButton>
        </div>
      </div>
      <div class="usercontent__list-table">
        <div class="usercontent__list-table-header">
          <p class="usercontent__list-table-header-no">No</p>
          <p class="usercontent__list-table-header-code">Code</p>
          <p class="usercontent__list-table-header-name">Name</p>
          <p class="usercontent__list-table-header-city">Join Date</p>
          <p class="usercontent__list-table-header-action">Action</p>
        </div>
        <div class="usercontent__list-table-body">
          <div v-if="validData && validData.length > 0">
            <div
              class="usercontent__list-table-body-user"
              v-for="(user, index) in validData"
              :key="index"
            >
              <p
                class="usercontent__list-table-body-no"
                @click="setDetailUser(user)"
              >
                {{ index + 1 }}
              </p>
              <p
                class="usercontent__list-table-body-code"
                @click="setDetailUser(user)"
              >
                {{ user.code }}
              </p>
              <p
                class="usercontent__list-table-body-name"
                @click="setDetailUser(user)"
              >
                {{ user.name }}
              </p>
              <p
                class="usercontent__list-table-body-city"
                @click="setDetailUser(user)"
              >
                {{ moment(user?.created_at).format("DD MMM YYYY") || "-" }}
              </p>
              <div class="usercontent__list-table-body-action">
                <CustomButton
                  :button-display="false"
                  :button-text="'Edit'"
                  :background-color="'#0099CC'"
                  :padding-top="'0.5rem'"
                  :padding-bottom="'0.5rem'"
                  :padding-left="'0px'"
                  :padding-right="'0px'"
                  :width="'100%'"
                  :flex="'0.5'"
                  :image="require('@/assets/admin/icon/edit.png')"
                  @buttonClicked="editUser(user)"
                ></CustomButton>
                <div class="usercontent__half usercontent__grayscale">
                  <CustomButton
                    :button-display="false"
                    :button-text="'Delete'"
                    :background-color="'#FA5C7C'"
                    :padding-top="'0.5rem'"
                    :padding-bottom="'0.5rem'"
                    :padding-left="'0px'"
                    :padding-right="'0px'"
                    :width="'100%'"
                    :flex="'0.5'"
                    :image="require('@/assets/admin/icon/trash.svg')"
                  ></CustomButton>
                  <!-- @buttonClicked="deleteUser(user.id)" -->
                </div>
              </div>
            </div>
          </div>
          <div class="usercontent__list-table-body-user_no-data" v-else>
            <p class="usercontent__list-table-body-user_no-data-text">
              Tidak ada data
            </p>
          </div>
        </div>
      </div>
      <div class="usercontent__list-pagination">
        <!-- Go Back -->
        <div
          class="usercontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
          v-if="paginationOption.currentPage - 1 > 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>

        <div
          class="usercontent__list-pagination-first"
          v-if="paginationOption.currentPage > 2"
        >
          <p
            class="usercontent__list-pagination-number"
            @click="setCurrentPage(1)"
          >
            1
          </p>
          <p
            class="usercontent__list-pagination-number"
            v-if="paginationOption.currentPage > 3"
          >
            . . .
          </p>
        </div>

        <!-- Previous Page -->
        <p
          class="usercontent__list-pagination-number"
          v-if="paginationOption.currentPage - 1 > 0"
          @click="setCurrentPage(paginationOption.currentPage - 1)"
        >
          {{ paginationOption.currentPage - 1 }}
        </p>

        <!-- Current Page -->
        <p
          class="usercontent__list-pagination-number usercontent__list-pagination-number_bold"
        >
          {{ paginationOption.currentPage }}
        </p>

        <!-- Next Page -->
        <p
          class="usercontent__list-pagination-number"
          v-if="paginationOption.currentPage + 1 < paginationOption.totalPage"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
        >
          {{ paginationOption.currentPage + 1 }}
        </p>

        <div
          class="usercontent__list-pagination-first"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <p
            class="usercontent__list-pagination-number"
            v-if="
              paginationOption.currentPage + 3 <= paginationOption.totalPage
            "
          >
            . . .
          </p>
          <p
            class="usercontent__list-pagination-number"
            @click="setCurrentPage(paginationOption.totalPage)"
          >
            {{ paginationOption.totalPage }}
          </p>
        </div>

        <!-- Go Next -->
        <div
          class="usercontent__list-pagination-chevron"
          @click="setCurrentPage(paginationOption.currentPage + 1)"
          v-if="paginationOption.currentPage < paginationOption.totalPage"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </div>
    <ModalDetailVue
      v-if="statusAddModal"
      :user-detail="detailUser"
      :type="type"
      @closeModal="hideAddModal"
      @createuser="onUserCreated()"
    ></ModalDetailVue>
  </div>
</template>

<script>
import CustomButton from "@/components/atoms/CustomButton.vue";
import ClickOutside from "vue-click-outside";
import ModalDetailVue from "./ModalDetail.vue";
import moment from "moment";

export default {
  name: "NavigationBar",
  components: {
    CustomButton,
    ModalDetailVue,
  },
  data() {
    return {
      userList: [],
      resultSearch: null,
      searchInput: null,
      selectedFilter: null,
      filterOpened: false,
      filterList: [
        {
          name: "Code",
          code: "code",
        },
        {
          name: "User Name",
          code: "name",
        },
      ],
      paginationOption: {
        limitPage: 10,
        totalPage: 1,
        currentPage: 1,
      },

      selectedSort: {
        name: "User Name",
        order_by: "name",
        sortAsc: false,
      },
      sortOpened: false,
      sortList: [
        {
          name: "Code",
          order_by: "code",
          sortAsc: false,
        },
        {
          name: "User Name",
          order_by: "name",
          sortAsc: false,
        },
        // {
        //   name: "Date",
        //   order_by: "created_at",
        //   sortAsc: false,
        // },
      ],
      statusAddModal: false,
      detailUser: {},
      type: "preview",
      buttonHovered: false,
      totalPage: null,
    };
  },
  mounted() {
    this.getUserList();
    this.setInitialFilter();
  },
  methods: {
    moment: moment,
    setDetailUser(user) {
      this.detailUser = user;
      this.setType("preview");
      this.showAddModal();
    },
    setInitialFilter() {
      this.selectedFilter = this.filterList[0];
    },
    setInitialSort() {
      this.selectedSort = this.sortList[0];
    },
    setSelectedFilter(params) {
      this.selectedFilter = params;
    },
    setSelectedSort(params) {
      if (this.selectedSort.order_by === params.order_by) {
        this.selectedSort.sortAsc = !this.selectedSort.sortAsc;
      } else {
        this.selectedSort = params;
      }

      this.userList.sort((a, b) => {
        let text_one = a[params.order_by];
        let text_two = b[params.order_by];

        if (this.selectedSort.sortAsc) {
          if (text_one < text_two) {
            return -1;
          }
          if (text_one > text_two) {
            return 1;
          }
          return 0;
        } else {
          if (text_one < text_two) {
            return 1;
          }
          if (text_one > text_two) {
            return -1;
          }
          return 0;
        }
      });

      // this.getUserList();
    },
    showAddModal() {
      this.statusAddModal = true;
    },
    hideAddModal() {
      this.statusAddModal = false;
    },
    setType(val) {
      this.type = val;
    },
    async getUserList() {
      try {
        const params = {
          limit: this.paginationOption.limitPage,
          offset:
            (this.paginationOption.currentPage - 1) *
            this.paginationOption.limitPage,
          order_by: {
            created_at: "desc",
          },
          // order_sort: this.selectedSort.sortAsc ? "asc" : "desc",
        };
        const response = await this.Api({
          url: "api/rest/users",
          formData: params,
        }).post();
        this.paginationOption.totalPage = Math.ceil(
          response?.body?.users_aggregate?.aggregate?.count /
            this.paginationOption.limitPage
        );
        this.totalPage = this.paginationOption.totalPage;
        this.userList = response.body.users;
        // this.getTotalPage(response.data.total_page);
      } catch {
        this.$toast.error("Maaf terjadi kesalahan");
      }
    },
    editUser(user) {
      this.detailUser = user;
      this.setType("edit");
      this.showAddModal();
    },
    async deleteUser(id) {
      try {
        const response = await this.Api({
          url: `api/rest/users/${id}`,
        }).delete();

        if (response) {
          this.$toast.success("User berhasil dihapus");
          this.getUserList();
        }
      } catch {
        this.$toast.error("Gagal menghapus user");
      }
    },
    getTotalPage(totalData) {
      this.paginationOption.totalPage = Math.ceil(
        totalData / this.paginationOption.limitPage
      );
    },
    searchData() {
      const lowerCaseinput = this.searchInput?.toLowerCase();
      if (lowerCaseinput) {
        this.resultSearch = this.userList.filter((dt) =>
          String(dt[this.selectedFilter.code])
            .toLowerCase()
            .includes(lowerCaseinput)
        );
        this.paginationOption.totalPage = 1;
      } else {
        this.resultSearch = null;
        this.paginationOption.totalPage = this.totalPage;
      }
    },
    toggleFilter() {
      this.filterOpened = !this.filterOpened;
    },
    closeFilter() {
      this.filterOpened = false;
    },
    toggleSort() {
      this.sortOpened = !this.sortOpened;
    },
    closeSort() {
      this.sortOpened = false;
    },
    setCurrentPage(page) {
      this.paginationOption.currentPage = page;
      this.getUserList();
    },
    resetSortList() {
      this.sortList.forEach((dt) => {
        dt.sortAsc = true;
      });
    },
    onUserCreated() {
      this.hideAddModal();
      this.getUserList();
    },
  },
  computed: {
    validData() {
      return this.resultSearch ? this.resultSearch : this.userList;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style>
@import "./usercontent.css";
</style>
